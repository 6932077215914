<template lang="pug">
div
  nav.layout-navbar.container-xxl.navbar.navbar-expand-xl.navbar-detached.align-items-center.vue-navbar.bg-navbar-custom
    .navbar-nav-right.d-flex.align-items-center
      ul.navbar-nav
        .app-brand
          a.app-brand-link(href="/start")
            span.app-brand-logo
              img(:src="'/Logo_TU_Dresden.svg'" alt="Logo")
        .navbar-nav.align-items-center
         .nav-item.d-flex.align-items-center
            svg(xmlns="http://www.w3.org/2000/svg" style="width: 24px; height: 24px;" viewBox="0 0 24 24"): path(fill="currentColor" d="M9.5,3A6.5,6.5 0 0,1 16,9.5C16,11.11 15.41,12.59 14.44,13.73L14.71,14H15.5L20.5,19L19,20.5L14,15.5V14.71L13.73,14.44C12.59,15.41 11.11,16 9.5,16A6.5,6.5 0 0,1 3,9.5A6.5,6.5 0 0,1 9.5,3M9.5,5C7,5 5,7 5,9.5C5,12 7,14 9.5,14C12,14 14,12 14,9.5C14,7 12,5 9.5,5Z")
            tags(
              v-model="tagList"
              v-if="tags"
              :options="tags"
            )
         .nav-item.mx-5
            .range-label(v-if="range") Jahre ({{range[0]}}-{{range[1]}})
            range(
              v-model="range"
              v-if="range"
              :min="min"
              :max="max"
              @change="fetch"
            )
    .d-flex.align-items-end
      .nav-item.align-items-end
        .align-items-end.float-end(v-html="html")
  .content-wrapper: .container-xxl.flex-grow-1.container-p-y: .row.mx-5.mt-5
    transition-group(name="fade")
      a.col-md-6.col-lg-4.themed-grid-col(
        v-for="e in exhibits"
        :key="e.id"
        :style="'background-image: url('+e.image+')'"
        :href="e.link"
        :aria-label="e.name"
        :title="e.name"
      )
</template>

<script>
import Tags from "./Tags.vue"
import Range from "./Range.vue"
export default {
  components: {Tags, Range},
  props: [],
  data () {
    return {
      min: null,
      max: null,
      range: null,
      tagList: "",
      exhibits: [],
      tags: null,
      html: $("#navbar-content").html()
    }
  },
  mounted() {
    this.fetch()
  },
  watch: {
    tagList(){
      this.fetch()
    }
  },
  methods:{
    fetch(){
      let queryString = {}
      if (this.tagList.length != 0) queryString.tags = this.tagList
      if (this.range){
        queryString.year_start = this.range[0]
        queryString.year_end = this.range[1]
      }
      const urlParams = new URLSearchParams(queryString);
      fetch("/api/exhibits.json?"+urlParams).then(response => response.json()).then( (data) => {
        this.exhibits = data.exhibits
        this.tags = data.tags
        this.min = data.min
        this.max = data.max
        if (!this.range) this.range = [data.min, data.max]
      })
    }
  }
}
</script>

<style scoped lang="scss">
.themed-grid-col{
  border: 8px solid #fff;
  aspect-ratio: 1 / 1;
  box-shadow: inset 0 0 10px rgba(0, 0, 0, .8);
  background-size: contain;
  background-repeat: no-repeat;
}
.row{
}
.fade-enter {
  opacity: 0;
}
.fade-enter-active {
  transition: all 1s;
}

.fade-leave {
}
.fade-leave-active {
  transition: all .3s;
  opacity: 0;
  transform: translateY(0);
  position: absolute;
}
.fade-move {
  transition: all .5s cubic-bezier(0.77, 0, 0.175, 1);
  @for $i from 1 through 15 {
    &:nth-child(#{$i}) {
       transition: transform .4s #{$i * 0.1}s cubic-bezier(0.77, 0, 0.175, 1);
    }
  }
}
</style>