<template lang="pug">
.slider(ref="slider")
</template>

<script>
import noUiSlider from 'nouislider';
export default {
  components: {
  },
  props: [
    "modelValue",
    "min",
    "max"
  ],
  data () {
    return {
    }
  },
  mounted () {
    let self = this
    let slider = noUiSlider.create(this.$refs.slider, {
      start: this.modelValue,
      range: {
        'min': [this.min],
        'max': [this.max]
      },
      connect: true,
      tooltips: false,
      format: {
          to: function (value) {
              return value + '';
          },
          from: function (value) {
              return Number(value.replace('', ''));
          }
      },
      step: 1,
    });
    slider.on('update.one', function (e) {
      self.$emit('update:modelValue', e)
    });

    slider.on('change.one', function (e) {
      self.$emit('change', e)
    });
  },
  watch: {
  },
  computed: {
  },
  methods: {
  }
}
</script>

