import { createApp } from 'vue'
import App from './App.vue'

document.addEventListener("turbo:load", () => {
  const mountEl = document.querySelector("#app");
  if (mountEl){
    if (mountEl.innerHTML.indexOf("data-v-") === -1){
      const app = createApp(App, { ...mountEl.dataset, innerHtml: mountEl.innerHTML })
      app.mount('#app')
    }else{
      mountEl.innerHTML = ""
      const app = createApp(App, { ...mountEl.dataset, innerHtml: mountEl.innerHTML })
      app.mount('#app')
    }
  }
});

